@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  display: flex;
}

header, main, footer {
  align-self: center;
  padding: 0 2rem;
}

h1 {
  margin: 16px 4px;
  font-size: 3rem;
}

main {
  max-width: 1024px;
}

p {
  margin: 8px;
  font-size: 1.25rem;
  line-height: 1.5;
}

footer {
  align-self: center;
  margin: 16px;
}

a {
  color: #000;
  -o-transition: color .15s ease-out;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  transition: color .15s ease-out;
}

a:hover {
  color: #7fffd4;
}

.title {
  text-align: center;
}

.socials {
  font-size: 0;
  display: flex;
}

.socials a {
  margin-left: auto;
  margin-right: auto;
  padding: 4px;
}

.copyright {
  font-size: .75em;
}
/*# sourceMappingURL=index.7e0f2f66.css.map */
