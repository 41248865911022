@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    width: 100vw;

    font-family: 'Roboto', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

header, main, footer {
    align-self: center;
    padding: 0 2rem;
}

h1 {
    margin: 16px 4px;
    font-size: 3rem;
}

main  {
    max-width: 1024px;
}

p {
    margin: 8px;
    font-size: 1.25rem;
    line-height: 1.5;
}

footer {
    align-self: center;
    margin: 16px;
}

a {
    color: black;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    transition: color 0.15s ease-out;
    -moz-transition: color 0.15s ease-out;
    -o-transition: color 0.15s ease-out;
    -webkit-transition: color 0.15s ease-out;
}

a:hover {
    color: aquamarine;
}

.title {
    text-align: center;
}

.socials {
    font-size: 0;
    display: flex;
}

.socials a {
    padding: 4px;
    margin-left: auto;
    margin-right: auto;
}

.copyright {
    font-size: 0.75em;
}
